import { FesHero, Hero as HeroUI } from "@newt/ui";
import { FC } from "react";
import MockImg from "./assets/mv_mockup.png";
import HeroImgPc from "./assets/hero_pc.webp";
import HeroImgSp from "./assets/hero_sp.webp";
import HeroTitlePc from "./assets/hero_h1_pc.webp";
import HeroTitleSp from "./assets/hero_h1_sp.webp";
import { useAppLink } from "@utils/useAppLink";
import { TopBanners_QueryQuery } from "@graphql/types";
import { getFesHeroImages } from "./getFesHeroImages";

const ImgProps = {
  pc: {
    src: HeroTitlePc.src,
    width: 522.175,
    height: 90,
  },
  sp: {
    src: HeroTitleSp.src,
    width: 247,
    height: 111,
  },
};

const HEADER_IOS_DOWNLOAD =
  "https://app.adjust.com/yax7b7x?redirect_macos=https%3A%2F%2Fapps.apple.com%2Fjp%2Fapp%2Fid1585901351&redirect_windows=https%3A%2F%2Fapps.apple.com%2Fjp%2Fapp%2Fid1585901351";
const HEADER_ANDROID_DOWNLOAD =
  "https://app.adjust.com/f1bxs70?redirect_macos=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dnet.newt&redirect_windows=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dnet.newt";

interface HeroProps {
  fesTopBanners?: TopBanners_QueryQuery["fesTopBanners"];
}

export const Hero: FC<HeroProps> = (props) => {
  const { fesTopBanners } = props;
  const fesHeroProps = getFesHeroImages(fesTopBanners);

  const fdl =
    useAppLink({ utmCampaign: "tour_top", utmContent: "key_visual" }) || "";
  // (pc) adjust  → app store with redirect_macos or redirect_windows key
  // (sp) adjust  → firebase dynamic link with redirect key → open app if installed, otherwise redirect to app store
  const iosLink = HEADER_IOS_DOWNLOAD + "&redirect=" + encodeURIComponent(fdl);
  const androidLink =
    HEADER_ANDROID_DOWNLOAD + "&redirect=" + encodeURIComponent(fdl);

  return fesHeroProps ? (
    <FesHero {...fesHeroProps} />
  ) : (
    <HeroUI
      kvImgPcSrc={HeroImgPc.src}
      kvImgSpSrc={HeroImgSp.src}
      titleImgPcProps={ImgProps.pc}
      titleImgSpProps={ImgProps.sp}
      mockImgSrc={MockImg.src}
      iosLink={iosLink}
      androidLink={androidLink}
    />
  );
};
