import { Head, SearchBox, TopPageCatchphrase } from "@components/common";
import {
  searchFormValuesToQuery,
  useSearchForm,
} from "@components/common/SearchBox/hooks/useSearchForm";
import { DefaultLayout } from "@components/layout";
import {
  SectionBrowsingHistory,
  SectionRecommendation,
  SectionSearchHistory,
  useFetchSearchHistory,
} from "@components/pages/home";
import { SectionDestinationDetailLinks } from "@components/pages/home/SectionDestinationDetailLinks";
import { BannerFriendCode } from "@components/pages/home/BannerFriendCode";
import { SignInBanner } from "@components/pages/home/SignInBanner";
import {
  GlobalNavInSearchBox,
  Hero,
  SectionPopularDestinations,
} from "@components/pages/top";
import { SITE_CATCHPHRASE } from "@constants";
import {
  TopBanners_QueryQuery,
  airportsQuery,
  destinationsQuery,
} from "@graphql/types";
import { Container } from "@newt/ui";
import { NextPageWithLayout } from "@root/types";
import { useAnonymousUser, useAuth } from "@utils/auth";
import { graphql } from "@utils/graphql";
import { useBrazePageView } from "@utils/marketing/events/braze/pageViewEvent";
import { GetStaticProps } from "next";
import { ReactElement, useEffect } from "react";
import styles from "./index.module.css";
import { validateEnvVariables } from "@utils/validateEnvVariables";
import { Banner } from "@components/common/Banner";
import { ISR_REVALIDATE_TOUR_TOP } from "@root/constants/isrRevalidationTime";

interface HomePageProps {
  destinations: destinationsQuery;
  airports: airportsQuery;
  banners: TopBanners_QueryQuery;
}

export const getStaticProps: GetStaticProps<HomePageProps> = async ({}) => {
  validateEnvVariables();

  const [destinations, airports, banners] = await Promise.all([
    graphql.destinationsForTop(),
    graphql.airports(),
    graphql.TopBanners_Query(),
  ]);

  return {
    props: {
      destinations,
      airports,
      banners,
    },
    revalidate: ISR_REVALIDATE_TOUR_TOP,
  };
};

const HomePage: NextPageWithLayout<HomePageProps> = ({
  destinations,
  airports,
  banners,
}) => {
  const { user, isLoading } = useAuth();
  const anonymousUser = useAnonymousUser();
  const searchForm = useSearchForm(undefined, undefined, {
    resetScrollPosition: true,
  });

  const searchPageQuery = searchFormValuesToQuery(searchForm.getValues());
  const { histories } = useFetchSearchHistory({ enabled: true });
  const anonymousUserTourSearchHistory =
    anonymousUser.data?.anonymousUser.me?.tourSearchHistory.edges.map(
      (edge) => edge.node
    );

  useEffect(() => {
    if (user?.airportId) {
      searchForm.setValue("airportId", String(user?.airportId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.airportId]);

  // Braze Event
  useBrazePageView({ pageId: "tour_top" });

  return (
    <>
      <Head />
      <div className={styles.mv}>
        <Hero fesTopBanners={banners.fesTopBanners} />
        <div className={styles.searchBox}>
          <GlobalNavInSearchBox />
          <SearchBox
            rootClassName={styles.searchBoxInner}
            searchForm={searchForm}
            isLoading={isLoading}
            showSearchHistory={true}
            initialData={{
              airports,
              destinations,
            }}
          />

          <div className={styles.catchphraseForSp}>
            <TopPageCatchphrase
              text={SITE_CATCHPHRASE}
              variant="sp"
              size="xs"
            />
          </div>
        </div>
      </div>
      <main className={styles.main}>
        {banners.all.edges.length > 0 && (
          <SectionRecommendation banners={banners} />
        )}
        {user?.isActive ? (
          <Container>
            <BannerFriendCode />
          </Container>
        ) : (
          <SignInBanner />
        )}
        <SectionSearchHistory
          histories={histories || anonymousUserTourSearchHistory}
        />
        <SectionBrowsingHistory
          browsedTours={anonymousUser.data?.anonymousUser}
          isLoading={anonymousUser.isPending}
        />
        <SectionPopularDestinations
          searchPageQuery={searchPageQuery}
          destinations={destinations}
        />
        <Banner type="hotel" />
        <SectionDestinationDetailLinks
          destinationDetailLinks={destinations.destinations.edges}
        />
        <Banner type="mag" />
      </main>
    </>
  );
};

HomePage.getLayout = (page: ReactElement<HomePageProps>) => {
  return (
    <DefaultLayout
      destinations={page.props.destinations?.destinations}
      utmCampaign={"tour_top"}
      hasNavigation={false}
      catchphrase={SITE_CATCHPHRASE}
    >
      {page}
    </DefaultLayout>
  );
};

export default HomePage;
